export interface PricingPlan {
  name: SubscriptionPlan;
  price: string;
  description: string;
  properties: string[];
}

export enum SubscriptionPlan {
  Free = "free",
  OnDemand = "on-demand",
  Adventurer = "adventurer",
  Voyager = "voyager",
  VoyagerYearly = "voyager-yearly",
  Trailblazer = "trailblazer",
}

export interface PricingCardProps extends PricingPlan {
  isLast?: boolean;
}

export interface PlanFeatures {
  ["Active streams"]: string | number;
  ["Included usage"]: string | null;
  ["Extra usage"]: string | null;
  ["Datasets"]: {
    value: string;
    tooltipValue?: string;
  };
  ["BlockFunctions"]: {
    value: boolean;
    tooltipValue?: string;
  };
  ["Zero APIs"]: string;
  ["Destinations"]: {
    value: string;
    tooltipValue?: string;
  };
  ["Compression"]: {
    value: string | null;
    tooltipValue?: string;
  };
  ["Infrastructure"]: string;
}
