export enum ROUTES {
  HOME = "/",
  BLOG = "/blog",
  PRICING = "/pricing",
  PRIVACY_POLICY = "/privacy-policy",
  PRODUCTS = "/products",
  PRODUCT_STREAMING = "/products/streaming",
  PRODUCT_INDEX = "/products/indexers",
  PRODUCT_API = "/products/zero-apis",
  PRODUCT_ZERO_AI = "/products/zero-ai-agents",
  INTEGRATIONS = "/integrations",
  TERMS_OF_USE = "/terms-of-use",
}
