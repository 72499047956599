import React, { useEffect, useState } from "react";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { useContentful } from "react-contentful";
import { clsx } from "clsx";
import { useLocation } from "react-router-dom";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const { data: banner } = useContentful({
    contentType: "banner",
  });
  const { pathname } = useLocation();

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key.toLowerCase() === "s") {
        window.open("https://app.projectzero.io/", "_self");
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const _banner = banner as any;

  const parsed = _banner?.items[0]?.fields;

  const show = parsed?.show;

  return (
    <>
      <Header banner={parsed} open={open} setOpen={setOpen} />
      <main
        className={clsx({
          "sm:-mt-[28.313rem]": show && open,
          "sm:-mt-[5rem] -mt-[4.5rem]": show,
          "!bg-neutral-950": pathname === "/",
        })}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};
