export const parseHeroSectionData = (data: any) => {
  const parsed = data.items[0].fields;

  const cta = {
    label: parsed.link.fields.label,
    url: parsed.link.fields.url,
    image: {
      imgSrc: parsed.link.fields.image.fields.file.url,
      title: parsed.link.fields.image.title,
    },
  };

  const badgeText = parsed.badgeText;

  const video = {
    title: parsed.video.fields.title,
    url: parsed.video.fields.file.url,
  };

  const backedBy = parsed.backedBy.map(({ fields }: any) => ({
    label: fields.title,
    icon: fields.image.fields.file.url,
  }));

  const productHunt = parsed.productHunt.map(({ fields }: any) => ({
    imgSrc: fields.label,
    url: fields.url,
    isBlank: fields.isBlank,
  }));

  return {
    cta,
    badgeText,
    video,
    backedBy,
    productHunt,
  };
};

export const parseWhyWeDifferentData = (data: any) => {
  const parsed = data.items[0].fields;

  const cta = {
    label: parsed.cta.fields.label,
    url: parsed.cta.fields.url,
    icon: parsed.cta.fields.image.fields.file.url,
  };
  const title = parsed.title;
  const description = parsed.description;
  const cards = parsed.cards.map(({ fields }: any) => ({
    icon: fields.image.fields.file.url,
    title: fields.title,
  }));

  return { cta, title, description, cards };
};

export const parseFeaturesData = (data: any) => {
  const parsed = data.items[0].fields;

  const title = parsed.title;
  const description = parsed.description;
  const cta = {
    label: parsed.cta.fields.label,
    url: parsed.cta.fields.url,
    icon: parsed.cta.fields.image.fields.file.url,
  };

  const pZeroTitle = parsed.pZeroTitle;
  const pZeroDescription = parsed.pZeroDescription;
  const cards = parsed.cards.map(({ fields }: any) => ({
    title: fields.title,
    description: fields.description,
    icon: fields.image.fields.file.url,
  }));

  const coinCards = parsed.coinCards.map(({ fields }: any) => ({
    title: fields.title,
    description: fields.description,
  }));

  return {
    title,
    description,
    cta,
    pZeroTitle,
    pZeroDescription,
    cards,
    coinCards,
  };
};

export const parseBenefitsData = (data: any) => {
  const parsed = data.items[0].fields;

  const newTitle = parsed.newTitle;

  const video = parsed.video.fields.image.fields.file.url;

  const cards = parsed.cards.map(({ fields }: any) => ({
    title: fields.title,
    description: fields.description,
    image: {
      imgSrc: fields.image.fields.file.url,
      title: fields.image.fields.title,
    },
  }));

  const aiAgentTitle = parsed.aiAgentTitle;
  const aiAgentImage = parsed.aiAgentImage.fields.file.url;
  const aiAgentsCards = parsed.aiAgentsCards.map(({ fields }: any) => ({
    icon: fields.image.fields.file.url,
    title: fields.title,
    description: fields.description,
  }));

  return { cards, newTitle, aiAgentTitle, aiAgentImage, aiAgentsCards, video };
};
