export const DividedTitle = ({ title }: { title: string }) => {
  return (
    <div className="flex h-[64px] sm:h-[7.25rem] border-t border-b">
      <div className="flex sm:w-10 w-6 flex-none" />
      <div className="sm:grid sm:grid-cols-3 gap-x-6 w-full">
        <h3 className="border-r border-l text-2xl sm:text-5xl sm:px-6 py-4 px-6 sm:py-10 text-neutral-950">
          {title}
        </h3>
        <div className="sm:flex hidden border-r border-l" />
        <div className="sm:flex hidden border-r border-l" />
      </div>
      <div className="flex sm:w-10 flex-none w-6" />
    </div>
  );
};
