import { Dot } from "../../Home/components/Dot";
import { DotLabel } from "../../Home/components/DotLabel";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { clsx } from "clsx";
import React from "react";
import { DivideSection } from "../../../components/DivideSection";

export const Benefits = ({
  title,
  cards,
  heroCta,
  image,
  benefitCustomLabel,
}: {
  title: string;
  image: string;
  heroCta: any;
  cards: any[];
  benefitCustomLabel?: boolean;
}) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-x-6 px-6 flex flex-col border-t border-b sm:px-12">
      <div
        className="flex flex-col sm:col-span-1 h-[15rem] sm:h-auto bg-center bg-cover border-l border-r p-6"
        style={{ backgroundImage: `url('${image}')` }}
      >
        <div className="flex items-center">
          <Dot />
          <DotLabel label="benefits" />
        </div>
        <div className="sm:text-5xl text-2xl text-neutral-950 sm:my-6 my-4 font-medium tracking-tighter">
          {title}
        </div>
        <Link to={heroCta.url} className="mt-auto w-full">
          <Button
            mode="primary"
            className="sm:text-xl hover:animate-container hover:sm:px-6 p-3 sm:p-4 w-full justify-between"
          >
            <span>Start free</span>
            <span className="w-4 h-4 overflow-hidden sm:ml-2 relative">
              <img
                className="w-4 h-4 arrow-image"
                src={heroCta.icon}
                alt="icon"
              />
            </span>
          </Button>
        </Link>
      </div>
      <DivideSection
        side="full"
        className="!px-0 border-t border-b sm:hidden !h-6"
      />
      <div className="sm:grid sm:grid-cols-2 flex flex-col sm:col-span-2">
        {cards.map((card: any, index: number) => (
          <React.Fragment key={index}>
            <div
              className={clsx(
                "flex sm:flex-col flex-row sm:px-6 px-4 border-r py-6 sm:py-10 border-l",
                {
                  "sm:border-b":
                    index === 0 ||
                    index === 1 ||
                    (benefitCustomLabel && (index === 2 || index === 3)),
                  "sm:border-r": (index + 1) % 2 === 0,
                  "sm:border-r-0": (index + 1) % 2 !== 0,
                },
              )}
            >
              <img
                className="sm:w-8 sm:h-8 w-5 h-5 mr-2 sm:mr-0"
                src={card.icon}
                alt="icon"
              />
              <div className="flex flex-col">
                <h5 className="sm:text-2xl tracking-tighter sm:mt-6">
                  {card.title}
                </h5>
                <p className="font-medium text-sm sm:text-base text-neutral-500 sm:mt-4 tracking-tighter">
                  {card.description}
                </p>
              </div>
            </div>
            <DivideSection
              side="full"
              className={clsx("!px-0 border-t border-b sm:hidden !h-6", {
                hidden: index + 1 === cards.length,
              })}
            />
          </React.Fragment>
        ))}
        {benefitCustomLabel && (
          <div className="border-l border-r py-10 px-6 hidden sm:flex w-full h-full">
            <div className="ml-auto flex mt-auto uppercase font-medium text-sm">
              <span className="text-primary-400 mr-1">{"//"}</span>
              <span>Lighting-Fast Blockchain Data Access</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
