import React from "react";
import { DivideSection } from "./DivideSection";
import { Partners } from "../pages/blocks/Partners/Partners";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import { CTASection } from "../pages/blocks/CTASection/CTASection";

export interface IntegrationsPageLayoutProps {
  children: React.ReactNode;
  bgImage: string;
  title: string;
  description: string;
  cta: {
    link: string;
    label: string;
    icon?: string;
  };
}

export const IntegrationsPageLayout = ({
  children,
  bgImage,
  title,
  cta,
  description,
}: IntegrationsPageLayoutProps) => {
  return (
    <>
      <div
        className="flex flex-col bg-cover bg-no-repeat bg-center"
        style={{ backgroundImage: `url('${bgImage}')` }}
      >
        <DivideSection side="full" className="sm:!h-24 !h-20" />
        <div className="border-t sm:px-10 px-6">
          <div className="sm:py-[7.5rem] py-20 px-4 sm:space-y-8 space-y-6 flex flex-col items-center border-l border-r">
            <h5 className="sm:text-[4rem] text-3xl">{title}</h5>
            <p className="text-center sm:max-w-[40rem] text-base sm:text-xl font-medium">
              {description}
            </p>
            <Link to={cta.link} target="_blank" className="w-fit">
              <Button
                mode="primary"
                className="sm:!px-5 hover:animate-container !py-3 sm:!py-4 !px-4"
              >
                <span className="mr-1">{cta.label}</span>
                <span className="w-3 h-3 relative overflow-hidden">
                  <img
                    src={cta.icon}
                    className="w-3 h-3 arrow-image"
                    alt="arrow-up-right"
                  />
                </span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Partners />
      <DivideSection side="full" />
      {children}
      <CTASection />
    </>
  );
};
