import { PlanFeatures, PricingPlan, SubscriptionPlan } from "./types";

export const pricingPlans: PricingPlan[] = [
  {
    name: SubscriptionPlan.Free,
    price: "Free",
    description: "Free plan",
    properties: [
      "1 active stream",
      "3 GB data usage",
      "Zero APIs",
      "All datasets (excl. traces)",
      "Block Functions",
      "Webhook destination",
      "Shared infrastructure",
    ],
  },
  {
    name: SubscriptionPlan.OnDemand,
    price: "$3/GB",
    description: "Billed monthly",
    properties: [
      "Unlimited streams",
      "Zero APIs",
      "All datasets",
      "Block Functions",
      "All destinations",
      "Flexible compression",
      "Shared infrastructure",
    ],
  },
  {
    name: SubscriptionPlan.Adventurer,
    price: "$49",
    description: "Billed monthly",
    properties: [
      "Unlimited streams",
      "25 GB data usage",
      "$2.5/GB for extra usage",
      "Zero APIs",
      "All datasets",
      "Block Functions",
      "All destinations",
      "Flexible compression",
      "Shared infrastructure",
    ],
  },
  {
    name: SubscriptionPlan.Voyager,
    price: "$199",
    description: "Billed yearly",
    properties: [
      "Unlimited streams",
      "120 GB data usage",
      "$2/GB for extra usage",
      "Zero APIs",
      "All datasets",
      "Block Functions",
      "All destinations",
      "Flexible compression",
      "Shared infrastructure",
    ],
  },
  {
    name: SubscriptionPlan.Trailblazer,
    price: "Enterprise",
    description: "Billed monthly",
    properties: [
      "Custom number of active streams",
      "Custom data usage size",
      "Custom extra usage",
      "Custom CUs",
      "Custom Additional CUs",
      "Zero APIs",
      "All datasets",
      "Block Functions",
      "All destinations",
      "Flexible compression",
      "Dedicated infrastructure",
      "Custom extra compute",
    ],
  },
];

export const planFeatures: PlanFeatures[] = [
  {
    "Active streams": 1,
    "Included usage": "3 GB",
    "Extra usage": null,
    Datasets: {
      value: "All (excl. traces)",
      tooltipValue:
        "Access a variety of chain datasets \n tailored for detailed blockchain insights. \n Check our docs to learn more.",
    },
    BlockFunctions: {
      value: true,
      tooltipValue:
        "Pre-filter and transform data on-chain to streamline your workflows.",
    },
    "Zero APIs": "Coming soon",
    Destinations: {
      value: "Webhook Only",
      tooltipValue:
        "Send data directly to your preferred tools. Check our integrations to learn more.",
    },
    Compression: {
      value: null,
      tooltipValue:
        "Reduce data size and optimize costs with efficient compression techniques.",
    },
    Infrastructure: "Shared",
  },
  {
    "Active streams": "Unlimited",
    "Included usage": null,
    "Extra usage": null,
    Datasets: {
      value: "All (incl. traces)",
      tooltipValue:
        "Access a variety of chain datasets \n tailored for detailed blockchain insights. \n Check our docs to learn more.",
    },
    BlockFunctions: {
      value: true,
      tooltipValue:
        "Pre-filter and transform data on-chain to streamline your workflows.",
    },
    "Zero APIs": "Coming soon",
    Destinations: {
      value: "All",
      tooltipValue:
        "Send data directly to your preferred tools. Check our integrations to learn more.",
    },
    Compression: {
      value: "Flexible",
      tooltipValue:
        "Reduce data size and optimize costs with efficient compression techniques.",
    },
    Infrastructure: "Shared",
  },
  {
    "Active streams": "Unlimited",
    "Included usage": "25 GB",
    "Extra usage": "$2.5/GB",
    Datasets: {
      value: "All (incl. traces)",
      tooltipValue:
        "Access a variety of chain datasets \n tailored for detailed blockchain insights. \n Check our docs to learn more.",
    },
    BlockFunctions: {
      value: true,
      tooltipValue:
        "Pre-filter and transform data on-chain to streamline your workflows.",
    },
    "Zero APIs": "Coming soon",
    Destinations: {
      value: "All",
      tooltipValue:
        "Send data directly to your preferred tools. Check our integrations to learn more.",
    },
    Compression: {
      value: "Flexible",
      tooltipValue:
        "Reduce data size and optimize costs with efficient compression techniques.",
    },
    Infrastructure: "Shared",
  },
  {
    "Active streams": "Unlimited",
    "Included usage": "120 GB",
    "Extra usage": "$2/GB",
    Datasets: {
      value: "All (incl. traces)",
      tooltipValue:
        "Access a variety of chain datasets \n tailored for detailed blockchain insights. \n Check our docs to learn more.",
    },
    BlockFunctions: {
      value: true,
      tooltipValue:
        "Pre-filter and transform data on-chain to streamline your workflows.",
    },
    "Zero APIs": "Coming soon",
    Destinations: {
      value: "All",
      tooltipValue:
        "Send data directly to your preferred tools. Check our integrations to learn more.",
    },
    Compression: {
      value: "Flexible",
      tooltipValue:
        "Reduce data size and optimize costs with efficient compression techniques.",
    },
    Infrastructure: "Shared",
  },
  {
    "Active streams": "Custom",
    "Included usage": "Custom",
    "Extra usage": "Custom",
    Datasets: {
      value: "All (incl. traces)",
      tooltipValue:
        "Access a variety of chain datasets \n tailored for detailed blockchain insights. \n Check our docs to learn more.",
    },
    BlockFunctions: {
      value: true,
      tooltipValue:
        "Pre-filter and transform data on-chain to streamline your workflows.",
    },
    "Zero APIs": "Coming soon",
    Destinations: {
      value: "All",
      tooltipValue:
        "Send data directly to your preferred tools. Check our integrations to learn more.",
    },
    Compression: {
      value: "Flexible",
      tooltipValue:
        "Reduce data size and optimize costs with efficient compression techniques.",
    },
    Infrastructure: "Dedicated",
  },
];
