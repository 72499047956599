import { DivideSection } from "../../../components/DivideSection";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import React from "react";
import { CTA } from "../../../types";
import { VideoBackground } from "../../../components/VideoBackground";

interface Props {
  badge: {
    icon: string;
    label: string;
  };
  title: string;
  description: string;
  heroCta: CTA;
  docsCta: CTA;
  bgImage?: string | null;
  isZeroAI?: boolean;
  bgVideo?: string | undefined;
}

export const ProductZeroAIandAPIHero = ({
  badge,
  heroCta,
  bgImage,
  docsCta,
  title,
  description,
  bgVideo,
}: Props) => {
  return (
    <div className="flex flex-col bg-neutral-25">
      <DivideSection
        side="full"
        className="sm:!px-12 !px-6 sm:!h-24 !h-[5.5rem]"
      />
      <div className="sm:mx-12 mx-6 flex flex-col border-t sm:grid sm:grid-cols-2 sm:gap-x-6 border-l border-r">
        <div className="flex flex-col sm:border-r sm:px-10 py-10 px-4 sm:py-[9rem]">
          <div className="flex items-center bg-white border rounded-full w-fit h-8 sm:h-11 p-3 sm:py-0 sm:px-5">
            <img
              className="sm:w-4 sm:h-4 w-2.5 h-2.5"
              src={badge.icon}
              alt="badge-icon"
            />
            <h5 className="sm:ml-2 ml-1 sm:text-xl text-xs">{badge.label}</h5>
          </div>
          <h3 className="sm:text-[4rem] sm:leading-[4rem] max-w-sm text-3xl sm:max-w-4xl text-neutral-950 mt-6">
            {title}
          </h3>
          <p className="sm:text-xl text-base font-medium max-w-sm sm:max-w-2xl mt-5 tracking-tighter text-neutral-700 sm:mt-8">
            {description}
          </p>
          <div className="flex items-center flex-wrap sm:mt-8 mt-4">
            <Link to={heroCta.url} className="mr-2 mt-2 sm:mt-0">
              <Button
                mode="primary"
                className="sm:!px-5 !px-4 !py-3 sm:py-4 hover:animate-container text-base sm:text-xl"
              >
                <span>{heroCta.label}</span>
                <span className="w-4 h-4 ml-2 overflow-hidden relative">
                  <img
                    className="w-4 h-4 arrow-image"
                    src={heroCta.icon}
                    alt="icon"
                  />
                </span>
              </Button>
            </Link>
            <Link to={docsCta?.url} className="mt-2 sm:mt-0">
              <Button
                mode="white"
                className="sm:!px-5 !px-4 border hover:animate-container !py-3 sm:py-4 text-base sm:text-xl"
              >
                <span>{docsCta?.label}</span>
                <span className="w-4 h-4 overflow-hidden relative ml-2">
                  <img
                    className="w-4 h-4 arrow-image"
                    src={docsCta?.icon}
                    alt="icon"
                  />
                </span>
              </Button>
            </Link>
          </div>
        </div>
        {bgVideo ? (
          <VideoBackground
            className="!block"
            videoSrc={bgVideo}
            childrenClass="!pt-0"
          >
            <div
              className="bg-contain bg-center bg-no-repeat sm:border-l h-[20rem] sm:h-full"
              style={{
                backgroundImage: bgImage ? `url('${bgImage}')` : "none",
              }}
            />
          </VideoBackground>
        ) : (
          <div
            className="bg-cover bg-center bg-no-repeat sm:border-l h-[20rem] sm:h-auto"
            style={{
              backgroundImage: bgImage ? `url('${bgImage}')` : "none",
            }}
          />
        )}
      </div>
    </div>
  );
};
