import { DivideSection } from "../../../components/DivideSection";
import { Article } from "../../../types";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { ROUTES } from "../../../constants";

export const HeroBlogCard = ({ article }: { article: Article }) => {
  const { title, content, image, id, date } = article;

  const contentPreview = content[1].content.map((c: any) => c.value);

  return (
    <div className="px-2 pt-2">
      <div className="rounded bg-neutral-950">
        <DivideSection side="middle-black" className="sm:!h-24 !h-20" isHero />
        <div className="sm:px-10 flex flex-col border-neutral-800 border-t sm:border-b sm:grid sm:grid-cols-2 sm:gap-6">
          <div
            className="sm:bg-cover bg-none sm:bg-no-repeat border-l border-neutral-800 border-r"
            style={{ backgroundImage: `url('${image}')` }}
          />
          <div className="sm:px-10 px-4 mx-4 sm:mx-0 sm:py-20 pt-10 pb-4 flex flex-col border-l border-neutral-800 border-r">
            <span className="text-neutral-500 font-medium text-sm sm:text-xl sm:leading-[1.5rem] tracking-tight">
              {dayjs(new Date(date)).format("MMM D, YYYY")}
            </span>
            <h3 className="sm:mt-8 mt-6 text-2xl sm:text-[3rem]">{title}</h3>
            <p className="text-neutral-250 mt-6 line-clamp-2 font-medium sm:text-xl sm:leading-[1.5rem] tracking-tight">
              {contentPreview}
            </p>
            <Link
              to={`${ROUTES.BLOG}/${id}`}
              className="w-fit mt-6 mb-10 sm:mb-0 sm:mt-8"
            >
              <Button
                mode="primary"
                className="sm:!py-2 hover:animate-container !py-1"
              >
                <span>Read full article</span>
                <span className="w-3 h-3 ml-2 relative overflow-hidden">
                  <img
                    className="w-3 h-3 arrow-image"
                    src="/icons/arrow-right-up.svg"
                    alt="arrow"
                  />
                </span>
              </Button>
            </Link>
          </div>
          <div className="flex sm:hidden border-t border-b border-neutral-800">
            <img
              className="flex px-4 border-l border-r border-neutral-800 w-full object-cover h-[11.25rem]"
              src={image}
              alt="hero-article"
            />
          </div>
        </div>
        <DivideSection side="middle-black" className="!h-6" isHero />
      </div>
    </div>
  );
};
