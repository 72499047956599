import { DividedTitle } from "./DividedTitle";
import { toolsMocks } from "../helpers";
import { IntegrationsCard } from "./IntegrationsCard";

export const Tools = () => {
  return (
    <div className="flex flex-col">
      <DividedTitle title="Tools" />
      <div className="sm:px-10 sm:grid sm:grid-cols-3 border-b sm:gap-6">
        {toolsMocks.map((tool, index) => (
          <IntegrationsCard
            link="https://app.projectzero.io/"
            {...tool}
            index={index}
            key={tool.title}
          />
        ))}
        <IntegrationsCard
          link="https://forms.fillout.com/t/8J371MkLjLus"
          icon="/icons/request-icon.svg"
          title="Request an integration"
          description="Suggest a new integration to expand your workflow."
        />
        <div className="border-l border-t border-r sm:block hidden" />
      </div>
    </div>
  );
};
