import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { clsx } from "clsx";

export const BlogCard = ({
  article,
  className,
  isPreview,
}: {
  article: any;
  className?: string;
  isPreview?: boolean;
}) => {
  return (
    <Link to={`${ROUTES.BLOG}/${article.id}`} className="group">
      <div
        className={clsx(
          className,
          "flex flex-col flex-1 border-r h-full border border-l overflow-hidden",
          {
            "border-neutral-850 border-t-0 border-b-0": isPreview,
            "group-hover:bg-gradient-to-r group-hover:from-white group-hover:via-primary-400/10 group-hover:to-primary-400/40 bg-white":
              !isPreview,
            "group-hover:bg-gradient-to-r group-hover:from-neutral-950 group-hover:via-neutral-850 group-hover:to-neutral-850 bg-neutral-950":
              isPreview,
          },
        )}
      >
        <div
          className={clsx("w-full flex flex-none sm:!h-[15rem] !h-[11.5rem]", {
            "p-2": isPreview,
          })}
        >
          <img
            src={article.image}
            className={clsx(
              "flex w-full sm:h-[15rem] h-[11.5rem] flex-none object-cover",
              {
                "rounded sm:!h-[14.5rem] !h-[11rem]": isPreview,
              },
            )}
            alt="article-preview"
          />
        </div>
        <div
          className={clsx(
            "sm:p-6 p-4 flex flex-col transition-all duration-150",
          )}
        >
          <span className="text-neutral-500 text-sm sm:text-base font-medium tracking-tight">
            {dayjs(new Date(article.date)).format("MMM D, YYYY")}
          </span>
          <h3 className="sm:mt-6 mt-4 text-slate-25 text-2xl sm:text-[1.75rem] tracking-tighter sm:leading-[1.75rem]">
            {article.title}
          </h3>
          <p className="text-neutral-500 text-sm sm:text-base mt-4 line-clamp-2 font-medium sm:leading-[1rem] tracking-tight">
            {article.previewText}
          </p>
        </div>
      </div>
    </Link>
  );
};
