import { Dot } from "../../Home/components/Dot";
import { DotLabel } from "../../Home/components/DotLabel";
import React from "react";
import { Button } from "../../../components/Button";
import { Link } from "react-router-dom";
import { clsx } from "clsx";
import { DivideSection } from "../../../components/DivideSection";

export const WhyZeroAI = ({ whyZeroAI }: any) => {
  return (
    <div className="border-b px-6 sm:px-12 border-t sm:grid sm:grid-cols-2 sm:gap-6">
      <div
        className="bg-cover bg-no-repeat flex flex-col border-r border-l p-4 sm:p-10"
        style={{ backgroundImage: `url('${whyZeroAI.image}')` }}
      >
        <div className="flex items-center">
          <Dot />
          <DotLabel label="why zero ai agents?" />
        </div>
        <h3 className="font-medium text-neutral-950 sm:text-5xl text-2xl tracking-tighter mt-4 sm:mt-6 leading-[2rem] sm:leading-[3rem]">
          The intelligence layer for decentralized AI
        </h3>
        <p className="font-medium sm:text-xl mt-4 sm:mt-6">
          Zero AI Agent bridges blockchain and AI, giving developers:
        </p>
        <Link to={whyZeroAI?.cta?.url || "/"} className="mt-4 sm:w-fit sm:mt-6">
          <Button
            mode="primary"
            className="sm:!px-5 sm:!py-4 hover:animate-container w-full sm:w-fit justify-between sm:text-xl"
          >
            <span>{whyZeroAI?.cta?.label}</span>
            <span className="w-4 h-4 ml-2 overflow-hidden relative">
              <img
                className="w-4 h-4 arrow-image"
                src={whyZeroAI?.cta?.icon}
                alt="some-icon"
              />
            </span>
          </Button>
        </Link>
      </div>
      <DivideSection
        side="full"
        className="!px-0 border-t border-b sm:hidden !h-6"
      />
      <div className="flex flex-col sm:space-y-6 border-r border-l">
        {whyZeroAI?.cards?.map((card: any, index: number) => (
          <React.Fragment key={index}>
            <div
              className={clsx(
                "flex flex-row sm:flex-col sm:space-y-6 px-4 py-6 bg-white sm:border-b sm:p-10 border-t",
                {
                  "border-t-0": index === 0,
                  "border-b-0": index === 2,
                },
              )}
            >
              <div className="sm:w-10 sm:h-10 w-5 h-5 flex flex-none mr-2 sm:mr-0">
                <img
                  className="sm:w-10 sm:h-10 w-5 h-5"
                  src={card?.icon}
                  alt="card-icon"
                />
              </div>
              <div className="flex flex-col sm:space-y-6">
                <p className="sm:text-3xl tracking-tighter font-medium">
                  {card?.title}
                </p>
                <h5 className="sm:text-xl">{card?.description}</h5>
              </div>
            </div>
            {index + 1 !== whyZeroAI?.cards?.length && (
              <DivideSection
                side="full"
                className="!px-0 border-t sm:hidden !h-6"
                insideClass="!border-l-0 !border-r-0 "
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
