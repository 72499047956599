import { CTA, DropdownProduct } from "../../types";

export const parseHeaderData = (data: any) => {
  const parsed = data.items[0].fields;
  const logo = {
    imgSrc: parsed.logo.fields.file.url,
    title: parsed.logo.fields.title,
  };

  const smallLogo = {
    imgSrc: parsed.smallLogo.fields.file.url,
    title: parsed.smallLogo.fields.title,
  };

  const signUp = {
    label: parsed.signUp.fields.label,
    url: parsed.signUp.fields.url,
    hide: parsed.signUp.fields.hide,
  };

  const links: CTA[] = parsed.content.map(({ fields }: any) => ({
    label: fields.label,
    url: fields.url,
    icon: "",
    isBlank: fields.isBlank || false,
  }));

  const products: DropdownProduct[] = parsed.dropdownProducts.map(
    ({ fields }: any) => ({
      icon: fields.productImage.fields.file.url,
      title: fields.title,
      cta: {
        label: fields.cta.fields.label,
        url: fields.cta.fields.url,
        icon: "",
      },
      description: fields.description,
      image: fields.image.fields.file.url,
    }),
  );

  return { logo, links, signUp, smallLogo, products };
};
