import React from "react";
import { clsx } from "clsx";

export const VideoBackground = ({
  videoSrc,
  children,
  className,
  layoutClass,
  childrenClass,
  videoClass,
}: {
  videoSrc: string;
  children: React.ReactNode;
  className?: string;
  childrenClass?: string;
  layoutClass?: string;
  videoClass?: string;
}) => {
  return (
    <div
      className={clsx(
        className,
        "relative hidden sm:block h-auto overflow-hidden",
      )}
    >
      <video
        autoPlay
        loop
        muted
        playsInline
        className={clsx(
          videoClass,
          "absolute inset-0 w-full h-full rounded object-cover z-0",
        )}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div
        className={clsx(childrenClass, "relative z-10 pt-20 rounded h-full")}
      >
        {children}
      </div>

      <div
        className={clsx(layoutClass, "absolute inset-0 rounded opacity-50 z-5")}
      ></div>
    </div>
  );
};
