import { useContentful } from "react-contentful";
import { parseBenefitsData } from "./helpers";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { clsx } from "clsx";
import { DivideSection } from "../../../components/DivideSection";
import { useInView } from "react-intersection-observer";

const agentImgSrc = [
  "/ai-launchpad/illustration-1.png",
  "/ai-launchpad/illustration-2.png",
  "/ai-launchpad/illustration-3.png",
];

export const Benefits = () => {
  const [imgSrc, setImgSrc] = useState(agentImgSrc[0]);
  const { data, error, fetched, loading } = useContentful({
    contentType: "benefits",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { cards, aiAgentTitle, aiAgentsCards, video } =
    parseBenefitsData(_data);

  return (
    <>
      <div className="border-t border-b sm:px-10 mx-6 sm:mx-0 border-neutral-850 flex flex-col">
        <div className="border-r border-l text-neutral-25 border-neutral-850 px-4 py-8 sm:px-10 sm:py-12">
          <h3 className="sm:text-[4rem] text-3xl leading-[1.75rem] sm:leading-[4rem] sm:max-w-3xl">
            Benefits of <br />
          </h3>{" "}
          <h3 className="sm:text-[4rem] mt-1 sm:mt-0 flex items-center sm:h-[4rem] h-[1.75rem] text-3xl leading-[1.75rem] sm:leading-[4rem] sm:max-w-3xl">
            AI{" "}
            <span className="inline-flex rounded-full sm:mb-1 mx-2 overflow-hidden">
              <img
                className="sm:h-14 h-8"
                src="/icons/avatar-bg.png"
                alt="avatar"
              />
            </span>{" "}
            Agents
          </h3>
        </div>
      </div>
      <div className="border-b sm:px-10 px-6 flex flex-col border-neutral-850 sm:grid sm:grid-cols-2 sm:gap-x-6">
        {cards.slice(0, 2).map((card: any, index: number) => (
          <Benefit
            benefit={card}
            isReversed={index === 1}
            isSmall
            key={index}
          />
        ))}
      </div>
      <MobileDivide />
      <div className="border-b sm:px-10 px-6 flex flex-col border-neutral-850 sm:grid sm:grid-cols-3 sm:gap-x-6">
        {cards.slice(2).map((card: any, index: number) => (
          <Benefit benefit={card} isReversed={index === 1} key={index} />
        ))}
      </div>
      <DivideSection isDark side="full" />
      <div className="flex flex-col border-t border-b border-neutral-850">
        <div className="relative sm:mx-10 sm:h-[100rem] h-[25rem] mb-[-18rem] sm:mb-[-75rem] bg-neutral-950 overflow-hidden">
          <video
            autoPlay
            loop
            muted
            playsInline
            className={clsx(
              "flex h-full border-l w-full border-r border-neutral-850 object-contain absolute top-[-14rem] sm:top-[-55rem] rounded z-0",
            )}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="flex flex-col z-10 items-center sm:mx-10 mx-6 bg-neutral-950 sm:pb-10 sm:border-l justify-center sm:border-r border-neutral-850">
          <div className="w-fit border-gradient sm:px-3 sm:py-2 px-2 py-1 sm:mb-6 mb-4">
            <h5 className="sm:text-xl !leading-none text-neutral-25">
              Coming soon
            </h5>
          </div>
          <h3 className="text-neutral-25 sm:text-[4rem] text-3xl leading-[1.75rem] sm:leading-[4rem] text-center max-w-[10rem] sm:max-w-xs">
            {aiAgentTitle}
          </h3>
        </div>
        <div className="flex relative sm:px-10 mt-6 sm:mt-auto px-6 border-t border-neutral-850">
          <div className="flex w-full bg-neutral-950 flex-col-reverse sm:flex-row">
            <div className="flex flex-col divide-y divide-neutral-850 w-full sm:!w-1/2">
              {aiAgentsCards.map((card: any, index: number) => (
                <AgentCard
                  card={card}
                  index={index}
                  key={index}
                  setImgSrc={setImgSrc}
                />
              ))}
            </div>
            <div className="z-10 sticky sm:top-[5rem] top-[4.5rem] border-r border-neutral-850 flex w-full flex-none sm:!w-1/2 h-[17rem] sm:h-[45rem]">
              <img
                src={imgSrc}
                alt="aiAgentImage"
                className="h-full w-full object-left object-cover transition-all duration-500"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Benefit = ({
  benefit,
  isReversed,
  isSmall,
}: {
  benefit: any;
  isSmall?: boolean;
  isReversed?: boolean;
}) => (
  <>
    <div
      className={clsx(
        "bg-neutral-950 border-neutral-850 flex sm:flex-col divide-y divide-neutral-850 sm:divide-y-0 flex-col-reverse border-l border-r",
        { "sm:!flex-col-reverse": isReversed },
      )}
    >
      <img
        className="sm:h-[18.75rem] object-cover h-[12.5rem] flex flex-none"
        src={benefit.image.imgSrc}
        alt={benefit.image.title}
      />
      <div className="sm:grid sm:grid-cols-2 p-4 sm:p-8">
        <div
          className={clsx("flex flex-col", {
            "col-span-2": !isSmall,
            "col-span-1": isSmall,
          })}
        >
          <h3 className="text-neutral-25 text-xl sm:text-[1.75rem] tracking-tighter sm:leading-[1.75rem]">
            {benefit.title}
          </h3>
          <p className="mt-4 text-sm sm:text-base text-neutral-500 font-medium tracking-tighter">
            {benefit.description}
          </p>
        </div>
      </div>
    </div>
  </>
);

const MobileDivide = () => (
  <div className="sm:flex hidden items-center w-full border-neutral-850 border-b">
    <div className="flex flex-none h-6 w-10" />
    <div className="w-full flex h-6 border-l border-neutral-850 border-r" />
    <div className="flex flex-none h-6 w-10" />
  </div>
);

const AgentCard = ({
  card,
  index,
  setImgSrc,
}: {
  card: any;
  index: number;
  setImgSrc: Dispatch<SetStateAction<string>>;
}) => {
  const { ref, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    inView && setImgSrc(agentImgSrc[index]);
  }, [inView, setImgSrc, index]);

  return (
    <div className="flex h-auto py-[8.5rem] sm:h-[45rem] justify-center border-l border-r border-neutral-850 flex-col px-6 sm:px-10">
      <img
        src={card.icon}
        alt="card-icon"
        className="sm:w-10 sm:h-10 h-6 w-6"
      />
      <p
        className="font-medium text-xl tracking-tighter mt-4 sm:mt-6 text-neutral-25 sm:text-3xl"
        ref={ref}
      >
        {card.title}
      </p>
      <p className="font-medium text-neutral-500 mt-3 sm:mt-4 tracking-tighter sm:text-xl">
        {card.description}
      </p>
    </div>
  );
};
