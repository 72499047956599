import { DividedTitle } from "./DividedTitle";
import { chainMocks } from "../helpers";
import { IntegrationsCard } from "./IntegrationsCard";

export const Chains = () => {
  return (
    <div className="flex flex-col">
      <DividedTitle title="Chains" />
      <div className="sm:px-10 sm:grid flex flex-col sm:grid-cols-3 border-b sm:gap-6">
        {chainMocks.map((chain, index) => (
          <IntegrationsCard
            link="https://app.projectzero.io/"
            {...chain}
            index={index}
            key={chain.title}
          />
        ))}
        <IntegrationsCard
          link="https://forms.fillout.com/t/catm8NZKQyus"
          icon="/icons/request-icon.svg"
          title="Request a new chain"
          description="Add support for an unlisted chain to suit your needs."
        />
        <div className="border-l border-t border-r hidden sm:block" />
        <div className="border-l border-t border-r hidden sm:block" />
      </div>
    </div>
  );
};
