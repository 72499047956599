import { Dot } from "../../Home/components/Dot";
import { DotLabel } from "../../Home/components/DotLabel";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import React from "react";

export const FeaturesInfo = ({
  title,
  url,
  icon,
  label,
}: {
  title: string;
  url: string;
  label: string;
  icon: string;
}) => {
  return (
    <div className="sm:px-12 px-6 flex flex-col sm:grid sm:grid-cols-2 sm:gap-x-6 border-t border-b">
      <div className="flex flex-col border-r border-l p-4 sm:py-10 sm:px-6">
        <div className="flex items-center">
          <Dot />
          <DotLabel label="features" />
        </div>
        <div className="font-medium sm:text-5xl tracking-tighter text-2xl sm:mt-6 mt-4">
          {title}
        </div>
      </div>
      <div className="flex flex-col border-r border-l p-4 sm:py-10 sm:px-6">
        <Link to={url} className="sm:mt-auto">
          <Button
            mode="white"
            className="sm:text-xl hover:sm:px-6 hover:animate-container border w-full sm:w-[18rem] p-3 sm:p-4 justify-between border-neutral-250"
          >
            <span>{label}</span>
            <span className="w-4 h-4 sm:ml-2 overflow-hidden relative">
              <img className="w-4 h-4 arrow-image" src={icon} alt="icon" />
            </span>
          </Button>
        </Link>
      </div>
    </div>
  );
};
