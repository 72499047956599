import { useContentful } from "react-contentful";
import { parseBigCTASectionData } from "./helpers";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";
import { clsx } from "clsx";

export const BigCTASection = ({
  withoutBenefits,
}: {
  withoutBenefits?: boolean;
}) => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "bigCtaSection",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const {
    bgImage,
    cta,
    image,
    description,
    title,
    benefits,
    mobileBgImage,
    mobileImage,
  } = parseBigCTASectionData(_data);

  const content = (isMobile?: boolean) => (
    <>
      <h3 className="sm:text-[4rem] px-4 sm:px-0 text-[2.5rem] tracking-tighter sm:text-neutral-25 text-neutral-950 sm:text-center text-left sm:leading-[4rem] leading-[2.5rem] max-w-[27.5rem]">
        {title}
      </h3>
      <p className="sm:text-xl mt-4 px-4 sm:px-0 sm:mt-8 tracking-tight sm:text-center text-left text-neutral-950 sm:text-neutral-25 sm:max-w-[44.5rem]">
        {description}
      </p>
      <Link to={cta.url} className="sm:mt-8 primary-button mt-6 px-4 sm:px-0">
        <Button
          mode={isMobile ? "white" : "primary"}
          className="sm:!px-5 sm:!py-4 py-3 hover:animate-container"
        >
          <span className="sm:mr-2 mr-1">{cta.label}</span>
          <span className="relative overflow-hidden w-4 h-4">
            <img
              className="arrow-image w-4 h-4"
              src={cta.image.imgSrc}
              alt={cta.image.title}
            />
          </span>
        </Button>
      </Link>
      {!withoutBenefits && (
        <div className="sm:grid sm:grid-cols-5 flex space-x-4 w-full pl-4 sm:pl-0 overflow-x-auto sm:gap-x-6 mt-10 sm:mt-20">
          {benefits.map((benefit: any, index: number) => (
            <div
              className="flex flex-col text-neutral-25 sm:space-y-4 space-y-3 items-start sm:items-center "
              key={index}
            >
              <div className="sm:h-8 sm:w-8 hidden sm:flex">
                <img src={benefit.icon.url} alt={benefit.icon.title} />
              </div>
              <div className="w-6 h-6 flex sm:hidden">
                <img
                  src={benefit.mobileIcon.url}
                  alt={benefit.mobileIcon.title}
                />
              </div>

              <div className="sm:text-xl sm:text-center text-left font-semibold text-neutral-950 sm:text-neutral-25 text-base">
                {benefit.title}
              </div>
              <h3 className="text-sm text-neutral-950 sm:text-neutral-25 font-medium break-words sm:break-normal sm:!text-base text-left w-[14rem] sm:w-full sm:!text-center">
                {benefit.description}
              </h3>
            </div>
          ))}
        </div>
      )}
      <img
        className={clsx("mt-8 hidden sm:block", {
          "!mt-20": withoutBenefits,
        })}
        src={image.imgSrc}
        alt={image.title}
      />
      <div className="mt-10 pl-4 sm:hidden flex">
        <img src={mobileImage.imgSrc} alt={mobileImage.title} />
      </div>
    </>
  );

  return (
    <div className="px-2">
      <div
        className="rounded border hidden sm:flex flex-col items-center pt-20 px-10 border-neutral-950 overflow-hidden bg-no-repeat bg-cover w-full bg-neutral-950"
        style={{ backgroundImage: `url('${bgImage.imgSrc}')` }}
      >
        {content()}
      </div>
      <div
        className="rounded border border-primary-400 sm:hidden flex flex-col items-start pt-8 overflow-hidden bg-no-repeat bg-cover w-full bg-primary-400"
        style={{ backgroundImage: `url('${mobileBgImage.imgSrc}')` }}
      >
        {content(true)}
      </div>
    </div>
  );
};
