import { useContentful } from "react-contentful";
import { parseWhyWeDifferentData } from "./helpers";
import { Link } from "react-router-dom";
import { Button } from "../../../components/Button";

export const WhyWeDifferent = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "whyWeDifferent",
  });

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { cta, title, description, cards } = parseWhyWeDifferentData(_data);

  return (
    <div className="flex text-neutral-25 border-t border-b flex-col border-neutral-850 px-6 sm:px-10">
      <div className="flex flex-col pt-8 items-center sm:pt-[3.5rem] border-r border-l border-neutral-850">
        <h3 className="text-center sm:text-[4rem] text-3xl leading-[1.75rem] sm:leading-[4rem] text-neutral-25 max-w-[14rem] sm:max-w-[28rem]">
          {title}
        </h3>
        <p className="text-neutral-250 sm:text-xl px-6 mt-4 tracking-tighter sm:mt-6 text-center font-medium sm:max-w-[32rem]">
          {description}
        </p>
        <Link
          to={cta.url}
          className="max-w-[12rem] w-full sm:mt-10 mt-6 mb-8 sm:mb-[3.5rem] primary-button"
        >
          <Button
            mode="primary"
            className="hover:animate-container sm:p-4 px-4 py-3 w-full justify-between"
          >
            <span>{cta.label}</span>
            <span className="w-4 h-4 relative overflow-hidden">
              <img src={cta.icon} alt="icon" className="w-4 h-4 arrow-image" />
            </span>
          </Button>
        </Link>
        <div className="sm:grid flex flex-col divide-y divide-neutral-850 sm:divide-y-0 border-t w-full border-neutral-850 sm:grid-cols-3">
          {cards.map((card: any, index: number) => (
            <div
              className="flex text-neutral-25 sm:p-10 p-6 flex-col items-center"
              key={index}
            >
              <img src={card.icon} className="h-6 sm:h-8" alt="icon" />
              <p className="sm:text-xl font-medium text-center mt-4 sm:mt-6">
                {card.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
