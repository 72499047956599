import { useContentful } from "react-contentful";
import { parseFooterData } from "./helpers";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../Button";
import { clsx } from "clsx";

export const Footer = () => {
  const { data, error, fetched, loading } = useContentful({
    contentType: "footer",
  });
  const { pathname } = useLocation();

  if (loading || !fetched || error) return null;

  const _data = data as any;

  if (!_data?.items?.length) return null;

  const { footerMenuData, ctaData, categoriesData } = parseFooterData(_data);

  const { bigLogo, logo, title, description, mobileBg } = footerMenuData;
  const { label, url, image } = ctaData;

  return (
    <div
      className={clsx("bg-neutral-1000 p-2", {
        "!bg-neutral-25": pathname !== "/",
      })}
    >
      <div
        className={clsx(
          "bg-neutral-950 rounded sm:p-10 px-4 py-6 flex flex-col",
          {
            "border border-neutral-850": pathname === "/",
            rounded: pathname !== "/",
          },
        )}
      >
        <img
          className="h-6 sm:h-8 w-fit"
          src={logo?.imgSrc}
          alt={logo?.title}
        />
        <div className="flex flex-col sm:flex-row sm:justify-between sm:space-x-2 mt-6 sm:mt-10">
          <div className="flex flex-col space-y-4 sm:space-y-6 sm:max-w-[21rem]">
            <h3 className="text-white text-2xl sm:text-[3rem] sm:leading-[3rem]">
              {title}
            </h3>
            <p className="text-neutral-250 sm:text-base text-sm max-w-sm sm:max-w-none">
              {description}
            </p>
            <Link to={url} className="group w-fit">
              <Button
                mode="primary"
                className="sm:!py-2 hover:animate-container !px-3 sm:!px-4 !py-1"
              >
                <span className="mr-1">{label}</span>
                <span className="w-3 h-3 relative overflow-hidden">
                  <img
                    src={image.imgSrc}
                    className="w-3 h-3 arrow-image"
                    alt={image.title}
                  />
                </span>
              </Button>
            </Link>
          </div>
          <div className="grid sm:grid-cols-5 mt-[4.5rem] sm:mt-0 sm:ml-auto grid-cols-2 gap-6">
            {categoriesData.map((category: any, index: number) => (
              <div
                className={clsx("flex flex-col font-semibold space-y-1", {
                  "col-span-2 !grid !grid-cols-2 sm:!flex sm:!col-span-1 sm:!flex-col":
                    index + 1 === categoriesData.length,
                })}
                key={index}
              >
                <span className="text-neutral-500 whitespace-nowrap text-sm sm:text-base sm:leading-6 p-2">
                  {category.title}
                </span>
                {category.links.map((link: any, index: number) => (
                  <Link
                    className="text-neutral-25 group hover:pl-4 hover:pr-0 flex items-center leading-6 rounded-sm transition-all duration-150 hover:bg-neutral-500/50 p-2"
                    to={link.url}
                    key={index}
                    target={link.isBlank ? "_blank" : "_self"}
                  >
                    {link.image && (
                      <img
                        className="w-4 h-4 mr-1"
                        src={link.image.imgSrc}
                        alt={link.image.title}
                      />
                    )}
                    <span className="text-sm sm:text-base">{link.label}</span>
                    <span className="flex ml-1.5 w-3">
                      <img
                        src="/icons/arrow-right-up-white.svg"
                        alt="arrow-up"
                        className="w-3 hidden group-hover:block"
                      />
                    </span>
                    <span className="w-2 flex flex-none" />
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
        <img
          src={bigLogo.imgSrc}
          alt={bigLogo.title}
          className="hidden sm:block"
        />
        <img
          src={mobileBg.imgSrc}
          alt={mobileBg.title}
          className="sm:hidden p-4"
        />
      </div>
    </div>
  );
};
