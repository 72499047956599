import React, { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { clsx } from "clsx";
import { CTA, DropdownProduct } from "../types";

function HamburgerMenu({
  links,
  products,
}: {
  links: CTA[];
  products: DropdownProduct[];
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const menuRef = useRef(null); // Ref for the menu
  const buttonRef = useRef(null); // Ref for the button

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        !(
          !menuRef.current ||
          //@ts-ignore
          menuRef.current?.contains(event.target) ||
          !buttonRef.current ||
          //@ts-ignore
          buttonRef.current?.contains(event.target)
        )
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <Menu as="div" className="relative">
        <div className="flex items-center justify-between">
          <Menu.Button
            ref={buttonRef}
            onClick={() => setIsOpen(!isOpen)}
            className="w-8 h-8 rounded-sm !bg-neutral-850 flex justify-center items-center focus:outline-none lg:hidden"
          >
            {!isOpen ? (
              <Bars3Icon className="w-3 text-neutral-25" />
            ) : (
              <XMarkIcon className="w-4 text-neutral-25" />
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          show={isOpen}
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 translate-x-10"
          enterTo="opacity-100 translate-x-0"
          leave="transition ease-in duration-150 transform"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-10"
        >
          <Menu.Items
            ref={menuRef}
            className="absolute right-0 w-48 mt-4 origin-top-right bg-neutral-850 rounded shadow ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div className="p-1">
              <Menu.Item>
                <div className="flex flex-col">
                  <button
                    onClick={() => setOpen(!open)}
                    className="text-neutral-25 cursor-pointer flex items-center leading-[1.5rem] text-sm px-4 py-2 font-medium tracking-tighter rounded-sm transition-all hover:bg-neutral-500/50 duration-150"
                  >
                    Products
                    <ChevronDownIcon
                      className={clsx("w-5 ml-1 transform text-neutral-500", {
                        "rotate-180": open,
                      })}
                    />
                  </button>
                  {open && (
                    <div className="flex ml-2 flex-col p-1">
                      {products.map(
                        (option: DropdownProduct, index: number) => (
                          <Link
                            onClick={() => {
                              setIsOpen(false);
                              setOpen(false);
                            }}
                            className="text-neutral-25 cursor-pointer leading-[1.5rem] text-sm px-4 py-2 font-medium tracking-tighter rounded-sm transition-all hover:bg-neutral-500/50 duration-150"
                            to={option.cta.url}
                            key={index}
                          >
                            {option.title}
                          </Link>
                        ),
                      )}
                    </div>
                  )}
                </div>
              </Menu.Item>

              {links.map((link: any, index: number) => (
                <Menu.Item key={index}>
                  <Link
                    onClick={() => setIsOpen(false)}
                    to={link.url}
                    className="block px-4 py-2 text-sm text-neutral-25"
                    target={link.isBlank ? "_blank" : "_self"}
                  >
                    {link.label}
                  </Link>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default HamburgerMenu;
